import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import BannerCollections from "../../components/molecules/banners/BannerCollections";
import BannerFeaturedDrops from "../../components/molecules/banners/BannerFeaturedDrops";
import { useStaticQuery, graphql } from "gatsby";
import urlConstants from "../../constants/urlConstants.js";
import ExploreCollectionBanner from "../../components/molecules/banners/ExploreCollectionBanner";
const apiService = require("../../services/apiService.js");

//get(url, form = { getHeaders: () => { return {} } }){

const IndexPage = ({ pageContext }) => {
  const bannerData = {
    title: "Latest | NFT Drops",
    url: "/drops",
    button_text: "View All Available Drops",
  };

  /**
  Get active NFT drops
  This needs to be a real time request to the api
*/

  const [dropList, setDropList] = useState([]);

  useEffect(() => {
    apiService.getter(urlConstants.getLatestDropsUrl).then((response) => {
      setDropList(response.data.drop);
    });
  }, []);

  const { strapiBannerCollection } = useStaticQuery(graphql`
    query {
      strapiBannerCollection {
        step_a_header
        step_a_text
        step_b_header
        step_b_text
        step_c_header
        step_c_text
        title
      }
    }
  `);
  /*
{drops.map((element) => {
  return (
    <li>
      <NftCardPrice
        key={element?.id}
        title={element?.name}
        nftCardImg={element?.cover_image?.url}
      />
    </li>
  );
})}

*/
  return (
    <Layout>
      <ul className="mt-32">
        <li>Collection links</li>
        {pageContext?.collections?.map((element) => {
          return (
            <li>/marketplace/nfts/virus-comics/collections/{element.slug}</li>
          );
        })}
      </ul>

      <ExploreCollectionBanner />

      <BannerCollections bannerData={strapiBannerCollection} />

      <BannerFeaturedDrops bannerData={bannerData} drops={dropList} />
    </Layout>
  );
};

export default IndexPage;
