import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import collectionsBanner from "../../../assets/images/collectionsBanner.png";
import TwoColorHeader from "../../atoms/TwoColorHeader";

const ExploreCollectionBanner = ({ onSearchHandler, scrollWondow }) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    scrollWondow();
  };
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${collectionsBanner})`,
        }}
        className="pt-44 pb-20 flex flex-col justify-center items-center gap-y-12 bg-cover bg-center"
      >
        <TwoColorHeader
          title="Explore|Collections"
          colorClassB="text-primary"
          className="text-6xl font-extrabold"
        />
        <form
          className="w-80 sm:w-96 h-12 flex bg-black"
          onSubmit={handleFormSubmit}
        >
          <input
            type="text"
            className="flex-grow bg-black text-white p-4 outline-none"
            placeholder="Search items, collections, creators and brands"
            onChange={onSearchHandler}
          />
          <button
            type="submit"
            className="h-12 w-12 bg-primary flex justify-center items-center rounded-bl-3xl"
          >
            <SearchIcon />
          </button>
        </form>
      </div>
    </>
  );
};

export default ExploreCollectionBanner;
